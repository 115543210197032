import React from 'react'

function ContactUs() {
  return (
    <div className='section contactUs'>        
            <h1 className='sectionTitle'>Contact Us</h1>        
        <div className='mapContainer'>          
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3958.5944047755315!2d125.4682583101607!3d7.17279449280213!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x32f9176e8cfeabb3%3A0x1c2aa04c80e8a195!2sSeawalk%20Industries%2C%20Inc.!5e0!3m2!1sen!2sph!4v1717759947901!5m2!1sen!2sph"                 
                style={{border: 0, height: 500, width: "100%",}} 
                allowFullScreen="" 
                loading="lazy" 
                referrerpolicy="no-referrer-when-downgrade"
                className='cu-Map'>                    
            </iframe>
        </div>
        <div className='contactUsText'>
            <h3 className='cuTextHeader'>Seawalk Industries Inc.</h3>                        
            <p className='cuTextParagraph'>
            To ensure we reach all our clients, Seawalk Industries is open from 8:00 AM to 5:00 PM, Monday to Saturday. We are available for meetings and on-site queries.             
            </p>
            <p className='cuTextParagraph'>You may also contact us here:</p>
            <p className='cuTextParagraph'>295-0832</p>
            <p className='cuTextParagraph'>+63-922-837-3222</p>
            <p className='cuTextParagraph'>seawalkindustries@yahoo.com</p>
            <p className='cuTextParagraph'>Purok 5A, Barangay Riverside, Calinan, Davao City</p>
            <a href="https://maps.app.goo.gl/LiuWwQ8op84Lj1XD7">
                <button className='contactUsBtn'>Click here to see directions in Google Maps</button>
            </a>
        </div>
            
          
   </div>
  )
}

export default ContactUs