import React, { Component } from "react";
import { Element } from 'react-scroll'
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import arrowLeft from '../images/arrow-left.png';
import arrowRight from '../images/arrow-right.png';

import client1 from '../images/Davao Lars Pic 1.png';
import client2 from '../images/DUBC CORPORATION 2.jpg';
import client3 from '../images/ALJI Construction.png';
import client4 from '../images/alpha omega.jpg';
import client5 from '../images/MAGAR CONSTRUCTION AND SUPPLY.jpg';

import clientLogo1 from '../images/client-logo.png';

class Clients extends Component {
  constructor() {
    super();
    this.slideRef = React.createRef();
    this.back = this.back.bind(this);
    this.next = this.next.bind(this);
    this.state = {
      current: 0
    };
  }

  back() {
    this.slideRef.current.goBack();
  }

  next() {
    this.slideRef.current.goNext();
  }

  render() {
    const properties = {
      duration: 5000,
      autoplay: false,
      transitionDuration: 500,
      arrows: false,
      infinite: true,
      easing: "ease",
      indicators: (i) => <div className="indicator">{i + 1}</div>
    };
  
    const slideInfo = [
        {
            // logo: clientLogo1,
            url: client1,
            title:"Ready Mix Concrete for Road Construction",
            // subtitle:"Ready Mix Concrete for Road Construction",
            text:"Barangay Wangan, Calinan District",
        },   
        {
            // logo: clientLogo1,
            url: client2,
            title:"Ready Mix Concrete for Road Construction",
            // subtitle:"",
            text:"Barangay Mulig, Toril",
        },   
        {
            // logo: clientLogo1,
            url: client3,
            title:"Ready Mix Concrete for Road Construction",
            // subtitle:"",
            text:"Marilog District",
        }, 
        {
            // logo: clientLogo1,
            url:client4,
            title:"Ready Mix Concrete for Slope Protection",
            // subtitle:"Ready Mix Concrete for Slope Protection",
            text:"Barangay Lubogan, Toril District",
        }, 
        {
            // logo: clientLogo1,
            url:client5,
            title:"Ready Mix Concrete for Road Construction",
            // subtitle:"",
            text:"Barangay Baganihan, Marilog District",
        }, 
    ];
    return (        
            <div className="clients section" id="ourClients">
                    <h1 className="sectionTitle">Clients & Projects</h1>
                    <p className="sectionSubtext">Seawalk Industries has supplied all types of construction projects, here are just a glimpse of our completed projects.</p>
                    <div className="clients-container">
                        <div className="slide-container desktopButtons">
                            <button onClick={this.back} type="button" className="clientsBtn">
                                <img src={arrowLeft} className="landing-arrowLeft" />
                            </button>
                        </div>
                        <div className="slide-container">
                            <Slide ref={this.slideRef} {...properties}>
                                {slideInfo.map((each, index) => (
                                    <div key={index} className="each-slide clientSlide">                                                   
                                        <img className="lazy clientImg" src={each.url} alt="client-img" />                                              
                                        <div className="mobileButtons">  
                            <button onClick={this.back} type="button" className="clientsBtn">
                                <img src={arrowLeft} className="landing-arrowLeft" />
                            </button>              
                            <button onClick={this.next} type="button" className="clientsBtn">
                                <img src={arrowRight} className="landing-arrowRight" />
                            </button>
                        </div>
                                        {/* <img className="lazy clientLogo" src={each.logo} alt="client-logo" />                                                                                                            */}
                                        <h3 className="clientsTitle">{each.title}</h3>
                                        {/* <p className="clientsSubtitle">{each.subtitle}</p> */}
                                        <p className="clientsText">{each.text}</p>
                                    </div>
                                ))}
                            </Slide>
                        </div>
                        <div className="slide-container desktopButtons">                
                            <button onClick={this.next} type="button" className="clientsBtn">
                                <img src={arrowRight} className="landing-arrowRight" />
                            </button>
                        </div>
                        
                    </div>        
                </div>        
    );
  }
}

export default Clients;
