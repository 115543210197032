import logo from './images/Logo.webp';
import './App.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
// import { HashLink } from 'react-router-hash-link';
import Products from './sections/Products';
import Clients from './sections/Clients';
import Hero from './sections/Hero';
import AboutUs from './sections/AboutUs';
import ContactUs from './sections/ContactUs';
import Footer from './sections/Footer';
import Navbar from './sections/Navbar';
import { Link, animateScroll} from "react-scroll";

function App() {
  return (
    <>    
      <Router>
        <Navbar/>
        {/* <nav>
              <div className='navbar-container'>                  
                  <div className='navigation-items x-lg nav-desktop'>
                      <Link  
                        to="hero" 
                        spy={true} 
                        smooth={true} 
                        duration={500}>                      
                        <img src={logo} alt="Seawalk Industries" className='nav-logo'/>
                      </Link>                                          
                      <Link  
                        to="aboutUs" 
                        spy={true} 
                        smooth={true} 
                        duration={500}                         
                        activeClass = "nav-linkActive" className='nav-link'>
                          About Us
                      </Link>
                      <Link  
                        spy={true} 
                        smooth={true} 
                        duration={500}                         
                        activeClass = "nav-linkActive" 
                        className='nav-link' 
                        to="productsServices">
                          Products & Services
                      </Link>
                      <Link  
                        spy={true} 
                        smooth={true} 
                        duration={500}                         
                        activeClass = "nav-linkActive" 
                        className='nav-link' 
                        to="ourClients">
                          Our Clients
                      </Link>      
                      <Link  
                        spy={true} 
                        smooth={true} 
                        duration={500}                         
                        activeClass = "nav-linkActive" 
                        className='nav-link' 
                        to="contactUs">
                          Contact Us
                      </Link>                                                        
                  </div>
                  <div className='navigation-items x-lg nav-mobile'>
                      <Link  
                        to="hero" 
                        spy={true} 
                        smooth={true} 
                        duration={500}>                      
                        <img src={logo} alt="Seawalk Industries" className='nav-logo'/>
                      </Link>                                          
                      <Link  
                        to="aboutUs" 
                        spy={true} 
                        smooth={true} 
                        duration={500}                         
                        activeClass = "nav-linkActive" className='nav-link'>
                          About Us
                      </Link>
                      <Link  
                        spy={true} 
                        smooth={true} 
                        duration={500}                         
                        activeClass = "nav-linkActive" 
                        className='nav-link' 
                        to="productsServices">
                          Products & Services
                      </Link>
                      <Link  
                        spy={true} 
                        smooth={true} 
                        duration={500}                         
                        activeClass = "nav-linkActive" 
                        className='nav-link' 
                        to="ourClients">
                          Our Clients
                      </Link>      
                      <Link  
                        spy={true} 
                        smooth={true} 
                        duration={500}                         
                        activeClass = "nav-linkActive" 
                        className='nav-link' 
                        to="contactUs">
                          Contact Us
                      </Link>                                                        
                  </div>
              </div>
          </nav>              */}
        <Routes>
          <Route path='/' exact />
        </Routes>
      </Router>    

      <Hero/>
      <AboutUs/>
      <Products/>
      <Clients/>
      <ContactUs/>
      <Footer/>

    </>
  );
}

export default App;
