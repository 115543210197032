import React from "react";

import companyProfile from "../assets/CompanyProfile.pdf";
import auImage from "../images/aboutus-img.webp";

function AboutUs() {
  return (
    <div className="aboutUs section" id="aboutUs">
      <h1 className="sectionTitle">About Us</h1>
      <div className="auContainer">
        <img className="auImage" src={auImage} />
        <div className="auTextContainer">
          <p className="auText">
            Seawalk Industries Inc. is the leading Ready Mixed Concrete (RMC)
            and Crushed Aggregates supplier in Davao City’s Calinan District.
            Starting with a crushing plant in 1993, Seawalk Industries has grown
            to be a trusted industry leader in producing high-quality
            construction materials for our clients. We take pride in our
            dedication to delivering top-notch products tailored to meet the
            diverse needs of our clientele. For all things RMC and Crushed
            Aggregates, think Seawalk Industries Inc.
          </p>
          <a
            href={companyProfile}
            download="SWICompanyProfile"
            target="_blank"
            className="auAnchor"
          >
            <button className="auBtn">Download company profile</button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
