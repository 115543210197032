import React from 'react'
import psConcrete from '../images/concrete_batching_2.jpg'
import mixerEquipment from '../images/concretemixer.jpg'
import crushAgg from '../images/crusher pic.jpg'
import culverts from '../images/culvert.jpg'

function Products() {
  return (
    <div className='section products' id='productsServices'>
      <h1 className='sectionTitle'>Products & Services</h1>  
      <div className='productsContainer'>
        <div className='productContainer'>
          <img src={psConcrete} className='productImage' />
          <h4 className='productHeader'>Ready Mix Concrete Batching</h4>
          <p className='productText'>We supply high-quality Ready Mixed Concrete (RMC) from our two batching plants to our clientele. Cost varies on the necessary concrete strength, cement factor and curing period.</p>
        </div>
        <div className='productContainer'>
          <img src={mixerEquipment} className='productImage' />
          <h4 className='productHeader'>Concrete Mixer and Pumpcrete Truck</h4>
          <p className='productText'>At Seawalk Industries Inc., we offer a range of concrete strength from 2500 to 5000 PSI and a curing period of 7, 14 and 28 days.</p>
        </div>
      </div>
      <div className='productsContainer'>
        <div className='productContainer'>
          <img src={crushAgg} className='productImage' />
          <h4 className='productHeader'>Crushed Aggregates</h4>
          <p className='productText'>Our offerings also include our aggregates: Gravel ¾, Gravel 1 ½ , Crushed sand (S-1), Coarse Sand, Waste Sand/Binder and Backfill.</p>
        </div>
        <div className='productContainer'>
          <img src={culverts} className='productImage' />
          <h4 className='productHeader'>Culverts</h4>
          <p className='productText'>We also offer Reinforced Concrete Pipe Culverts (RCPC) at 3000 PSI from 6” by 1 meter up to 72” by 1 meter. These can be picked up on site or delivered.</p>
        </div>
      </div>
    </div>
  )
}

export default Products