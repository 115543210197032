import React, { useState } from "react";
import { Link, animateScroll } from "react-scroll";
import { IoClose, IoMenu } from "react-icons/io5";
import logo from "../images/Logo.webp";

function Navbar() {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const closeMenuOnMobile = () => {
    if (window.innerWidth <= 1150) {
      setShowMenu(false);
    }
  };

  return (
    <nav>
      <div className="navbar-container">
        <div className="navigation-items x-lg nav-desktop">
          <Link to="hero" spy={true} smooth={true} duration={500}>
            <img src={logo} alt="Seawalk Industries" className="nav-logo" />
          </Link>
          <Link
            to="aboutUs"
            spy={true}
            smooth={true}
            duration={500}
            activeClass="nav-linkActive"
            className="nav-link"
          >
            About Us
          </Link>
          <Link
            spy={true}
            smooth={true}
            duration={500}
            activeClass="nav-linkActive"
            className="nav-link"
            to="productsServices"
          >
            Products & Services
          </Link>
          <Link
            spy={true}
            smooth={true}
            duration={500}
            activeClass="nav-linkActive"
            className="nav-link"
            to="ourClients"
          >
            Our Clients
          </Link>
          <Link
            spy={true}
            smooth={true}
            duration={500}
            activeClass="nav-linkActive"
            className="nav-link"
            to="contactUs"
          >
            Contact Us
          </Link>
          <div className="nav__toggle navNobile-menu" id="nav-toggle" onClick={toggleMenu}>
            <IoMenu />
            </div>
            <div className="nav__close" id="nav-close" onClick={toggleMenu}>
            <IoClose />
          </div>
        </div>
        <div className={`nav-mobile ${showMenu ? "show-menu" : ""}`} id="nav-menu">
          <Link
            to="aboutUs"
            spy={true}
            smooth={true}
            duration={500}
            activeClass="nav-linkActive"
            className="nav-link"
            onClick={closeMenuOnMobile}
          >
            About Us
          </Link>
          <Link
            spy={true}
            smooth={true}
            duration={500}
            activeClass="nav-linkActive"
            className="nav-link"
            to="productsServices"
            onClick={closeMenuOnMobile}
          >
            Products & Services
          </Link>
          <Link
            spy={true}
            smooth={true}
            duration={500}
            activeClass="nav-linkActive"
            className="nav-link"
            to="ourClients"
            onClick={closeMenuOnMobile}
          >
            Our Clients
          </Link>
          <Link
            spy={true}
            smooth={true}
            duration={500}
            activeClass="nav-linkActive"
            className="nav-link"
            to="contactUs"
            onClick={closeMenuOnMobile}
          >
            Contact Us
          </Link>          
        </div>
        
      </div>
    </nav>
  );
}

export default Navbar;
