import React from 'react'
import logo from '../images/Logo.webp';
function Footer() {
  return (
    <div className='footer'>
        <div>
            <img src={logo} className='footerLogo' />
        </div>        
        <div className='footer-textContainer'>
            <h4 className='footerHeader'>Seawalk Industries Inc.</h4>
            <p className='footerText'>Purok 5A, Barangay Riverside, Calinan, Davao City</p>
            <p className='footerText'>295-0832</p>
            <p className='footerText'>+63-922-837-3222</p>
            <p className='footerText'>seawalkindustries@yahoo.com</p>
        </div>
    </div>
  )
}

export default Footer