import React from "react";
import { Link, animateScroll } from "react-scroll";

import logo from "../images/seawalk logo new.png";
import arrowDown from "../images/arrow-down.png";

function Hero() {
  return (
    <div id="hero" className="landing section">
      <div className="landing-container">
        <img src={logo} className="landingLogo" />
        <h1 className="companyName">Seawalk Industries Inc.</h1>
        <p className="companyPhrase">Reliability and Quality is our Strength</p>
        <Link to="contactUs" spy={true} smooth={true} duration={500} offset={20}>
          <button className="landingBtn">Get started with us now</button>
        </Link>
      </div>
      <div className="linkTo-aboutUs">
        <p className="landingAU-text">Get Started With Us Now</p>
        <Link to="aboutUs" spy={true} smooth={true} duration={500} offset={20} className="hero-auBtn">
            <img src={arrowDown} className="landing-arrowDown" />
        </Link>        
      </div>
    </div>
  );
}

export default Hero;
